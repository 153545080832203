import React from "react";
import { useTable, useSortBy } from "react-table";
import './TableStyle.css';

export default function RSVPTableTemplate({ columns = [], data = [] }) {
  // Hooks must be called before any conditional return
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: 'StartDate', desc: false }],
      },
    },
    useSortBy
  );

  // Handle loading state AFTER Hooks
  if (columns.length === 0 || data.length === 0) {
    return <div>Loading...</div>;
  }

  const containerStyle = {
    borderRadius: "25px",
    height: "250px",
  };

  const innerContainerStyle = {
    overflow: "hidden",
  };

  return (
    <div style={containerStyle}>
      <div style={innerContainerStyle}>
        <table {...getTableProps()} className="rsvpTable">
          <thead className="rsvpTableThead">
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderProps } = headerGroup.getHeaderGroupProps();
              return (
                <tr key={key} {...restHeaderProps} className="rsvpTableTr">
                  {headerGroup.headers.map((column) => {
                    const { key, ...restColumnProps } = column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th key={key} {...restColumnProps}>
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { key, ...restRowProps } = row.getRowProps();
              return (
                <tr key={key} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps();
                    return <td key={key} {...restCellProps}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}