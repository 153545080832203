import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import './NavTopSubMenu.css'; // Import the CSS file

const NavTopSubMenu = ({ logout }) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [canSeeReports, setCanSeeReports] = useState(false);

    
    useEffect(() => {
        const fetchPermissions = async () => {

            //get from local storage cache
            //check if the boolean result is cached in local storage

            var cached = localStorage.getItem('canSeeReports');
            var cachedExpDate = localStorage.getItem('canSeeReportsExpiration');
            var now = new Date();
            if (cachedExpDate){
                cachedExpDate = new Date(cachedExpDate);
                if (isNaN(cachedExpDate) || now > cachedExpDate){
                    console.log('canSeeReport cache expired');
                    localStorage.removeItem('canSeeReports');
                    localStorage.removeItem('canSeeReportsExpiration');

                    if (cached === 'true') {
                        setCanSeeReports(true);
                    }
                    if (cached === 'false') {
                        setCanSeeReports(false);
                    }
                    cached = null;
                }
            }
            if (cached === 'true') {
                setCanSeeReports(true);
                return;
            }
            if (cached === 'false') {
                setCanSeeReports(false);
                return;
            }
            try {
                console.log('checking UI button permissions');
                function getCookie(name) {
                    let cookies = document.cookie.split('; ');
                    for (let cookie of cookies) {
                        let [key, value] = cookie.split('=');
                        if (key === name) {
                            return decodeURIComponent(value);
                        }
                    }
                    return null;
                }
                
                let idToken = getCookie('msal.44facb45-e540-41a9-8ff3-ebb6b6bf5498.idtoken');

                var expiration = new Date();
                expiration.setHours(expiration.getHours() + 1);

                const response = axios.get('https://seiu-mrc.azurewebsites.net/api/CanUserSeeReport?code=mFudbFqK94yVyY9NW0LHUZIdT4wdbYgNRAXNdxgIzm9CAzFubwr11w==', { headers: {SendAuthorization: 'Bearer ' + idToken}}).then(response => response.data)
                .then((data) => {
                    setCanSeeReports(true);
                    localStorage.setItem('canSeeReports', 'true');
                    localStorage.setItem('canSeeReportsExpiration', expiration);
                }).catch((error) => {
                    //console.error("Error fetching permissions:", error);
                    setCanSeeReports(false);
                    localStorage.setItem('canSeeReports', 'false');
                    localStorage.setItem('canSeeReportsExpiration', expiration);
                });
                
            } catch (error) {
                console.error("Error fetching permissions:", error);
            }
        };

        fetchPermissions();
    }, []);


    const handleToggle = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleClose = () => {
        setIsNavOpen(false);
    };

    const handleLogout = () => {
        handleClose();
        logout();

        // Select the element
        const navbarTogglerIcon = document.querySelector('span.navbar-toggler-icon');

        // Remove it from the DOM
        if (navbarTogglerIcon) {
            navbarTogglerIcon.remove();
        }
    };

    const injectFreshdeskScript = () => {
        window.fwSettings = {
            'widget_id': 36000001072
        };

        (function() {
            if (typeof window.FreshworksWidget !== "function") {
                var n = function() {
                    n.q.push(arguments);
                };
                n.q = [];
                window.FreshworksWidget = n;
            }
        })();

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://widget.freshworks.com/widgets/36000001072.js';
        script.async = true;
        script.defer = true;
        script.onload = () => {
            if (window.FreshworksWidget) {
                window.FreshworksWidget('open');
            }
        };
        document.head.appendChild(script);
    };

    return (
        <Container>
            {/* Navbar Toggle Button */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="subnav-links flex-container">
                    {/* Main Navigation Links */}
                    {canSeeReports && (<Nav.Item className="nav-item">
                        <Nav.Link href="/dashboardgate" onClick={handleClose} className="nav-link nav-item-font">
                            Union Intelligence
                        </Nav.Link>
                    </Nav.Item>)}
                    <Nav.Item className="nav-item">
                        <Nav.Link href="https://teach.seiu1021.org/loginsaml.aspx" target="_blank" rel="noreferrer" onClick={handleClose} className="nav-link nav-item-font">
                            Digital Learning
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                        <Nav.Link href="/rsvpeventspage" onClick={handleClose} className="nav-link nav-item-font">
                            Event Registration
                        </Nav.Link>
                    </Nav.Item>
                    {/* Dropdowns */}
                    <NavDropdown title="Contracts/Bylaws" id="contracts-bylaws-dropdown" className="dropdown nav-item-font">
                        <NavDropdown.Item href="/contractdocs" onClick={handleClose} className="dropdown-item nav-item-font">
                            My Contract and Bylaws
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://d3jpbvtfqku4tu.cloudfront.net/img/ConstitutionBylaws.pdf" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            International Constitution and Bylaws
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiu1021.org/sites/main/files/file-attachments/seiu1021-bylaws_09-2012.pdf?1595547741" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            SEIU Local 1021 Bylaws
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiu1021.org/find-your-chapter-contract" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            SEIU Local 1021 all contracts
                        </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Tool Kits" id="tool-kits-dropdown" className="dropdown nav-item-font">
                        <NavDropdown.Item href="https://www.seiu1021.org/sites/main/files/file-attachments/chapter_leader_resources_request_manual.pdf" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            How to request digital resources
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiu1021.org/sites/main/files/mobile_app_installation_guide.pdf" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            Mobile App Manual
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiu1021.org/sites/main/files/union_intelligence_mrc_portal_user_guide.pdf" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            Union Intelligence Manual
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://vimeo.com/757658689" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            Portal Video Guide
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiumb.com/" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            Member Benefits & Discounts
                        </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Resources" id="resources-dropdown" className="dropdown nav-item-font">
                        <NavDropdown.Item href="https://memberlink.seiu1021.org/SEIU1021MemberApplicationStep1.aspx" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Membership Application
                        </NavDropdown.Item>
                        <NavDropdown.Item href={require("../docs/Member-general-expenses_policy-form_4.19.16.pdf")} target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Member General Expense Policy
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiu1021.org/sites/main/files/file-attachments/policy_code_of_conduct_ebd-approved_2021-10-23.pdf?1635291070" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Code of Conduct
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiu1021.org/sites/main/files/file-attachments/policy_localwide-code_of_ethics_ebd-adopted_2019-09-29.pdf?1572926574" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Code of Ethics Policy
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiu1021.org/sites/main/files/file-attachments/policy_disputes-between-members_rev_ebd-approved_2014-11-22_1.pdf?1523993873" eventKey="4.1" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Policy Disputes Between Members
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://secure.na2.adobesign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhCY6ZC242UElL5E2N4rTiSt_3xq6IDfBP03IHvBenVZhgTLemwNwSgGQzlC_KIo3hc*" eventKey="4.1" target="_self" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Chapter Authorization to issue Chapter Funds
                        </NavDropdown.Item>
                        <NavDropdown.Item href={require("../docs/Chapter Signatory Form[99313].pdf")} eventKey="4.1" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Chapter Signatory
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.callhub.io" eventKey="4.1" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Virtual Phonebank
                        </NavDropdown.Item>
                        <NavDropdown.Item href={require("../docs/MRC_Portal_FAQ.pdf")} eventKey="4.1" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Frequently Asked Questions
                        </NavDropdown.Item>
                    </NavDropdown>
                    {/* <Nav.Item className="nav-item">
                        <Nav.Link href="#" onClick={() => { handleClose(); injectFreshdeskScript(); }} className="nav-link nav-item-font">
                            Help
                        </Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item className="nav-item">
                      <Nav.Link href="#" onClick={handleLogout} className="nav-link nav-item-font">
                        Logout
                      </Nav.Link>
                   </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Container>
    );
};

export default NavTopSubMenu;