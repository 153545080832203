import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import Table from "./RSVPTableTemplate";
import { Button, Spinner } from "react-bootstrap";

export function RSVPUpcomingEvents(props) {
  const [allEvents, setAllEvents] = useState([]);
  const [data, setData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();

  const register = useCallback((eventID) => {
    console.log(`Registering member ${props.memberID} for event ${eventID}`);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    let uri = `https://prod-08.westus.logic.azure.com/workflows/335a123bf43d4895b0a4871a6da05928/triggers/manual/paths/invoke/${props.memberID}?EventID=${eventID}&api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=jfLaO_vo-jSDVkqGiHkaUDLRJe7Kh4GACI94WVZr9CU`;
    axios.get(uri, {}, axiosConfig).then(
      (response) => {
        alert(`Registration successful for ${eventID}`);
        window.location.reload(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [props.memberID]);

  useEffect(() => {
    (async () => {
      let queryParams = new URLSearchParams(window.location.search);
      const locationNameFromQueryString = queryParams.get("location");

      if (allEvents.length === 0) { 
        const result = await axios(
          "https://prod-100.westus.logic.azure.com:443/workflows/a389ca49a9ec42a69ea800fa4bfb8597/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=U0ZtqNvNoa6Fo1OusEaUJfn6q5Gy3Qr3e6hjyXINsUk"
        );

        setAllEvents(result.data);
        setData(result.data);

        let _locations = [];
        result.data.forEach((element) => {
          if (element.LocationName && !_locations.includes(element.LocationName)) {
            _locations.push(element.LocationName);
          }
        });

        setLocations(_locations);
      } else { 
        let effectiveSelectedLocation = locationNameFromQueryString || selectedLocation;

        if (effectiveSelectedLocation && effectiveSelectedLocation !== "See all locations") {
          let _data = allEvents.filter(element => element.LocationName === effectiveSelectedLocation);
          setData(_data);
          setSelectedLocation(effectiveSelectedLocation);
        } else {
          setData(allEvents);
        }
      }
    })();
  }, [selectedLocation, locations, allEvents]);

  const formatDateTime = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    return (
      <div style={{ textAlign: "left", verticalAlign: "top", whiteSpace: "nowrap" }}>
        <strong>{`${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear().toString().slice(-2)}`}</strong>
        <br />
        {`${startDate.toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: true })} - ${endDate.toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: true })}`}
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Upcoming Events",
        columns: [
          {
            Header: "Upcoming Events",
            accessor: "Name",
            width: 100,
            maxWidth: 100,
            minWidth: 50,
            Cell: ({ value }) => (
              <div style={{ textAlign: "left", verticalAlign: "top", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {value}
              </div>
            ),
          },
          {
            Header: "Start Date",
            accessor: "StartDate",
            width: 150,
            Cell: ({ row }) => (
              <div style={{ textAlign: "left", verticalAlign: "top" }}>
                {formatDateTime(row.original.StartDate, row.original.EndDate)}
              </div>
            ),
            sortType: (a, b) => new Date(a.original.StartDate) - new Date(b.original.StartDate),
          },
          {
            Header: "Location",
            accessor: "LocationName",
          },
          {
            Header: "Register",
            accessor: (d) => {
              return (
                <Button
                  className="mx-2 btn-events"
                  onClick={() => register(d.EventID)}
                >
                  Register
                </Button>
              );
            },
          },
        ],
      },
    ],
    [register]
  );

  return (
    <div>
      {data.length > 0 ? (
        <div>
          <Table columns={columns} data={data} />
        </div>
      ) : (
        <div
          className="div-loading"
          style={{
            color: "purple",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Spinner animation="border" style={{ color: "purple" }} />
          <h1>Fetching Upcoming Events... </h1>
        </div>
      )}
    </div>
  );
}