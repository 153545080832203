import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import * as Msal from "msal";
import NavTop from "./Components/NavTop";
// import NavTopSubMenu from "./Components/NavTopSubMenu";
import RSVPEventsPage from "./Components/RSVPEventsPage";
import { DashBoardGate } from "./Components/DashBoardGate";
import ReportEmbed from "./Components/ReportEmbed";
import { RSVPUpcomingEvents } from "./Components/RSVPUpcomingEvents";
import { RSVPRegisteredEvents } from "./Components/RSVPRegisteredEvents";
import { ContractDocs } from "./Components/ContractDocs";
import Barcode from "./Components/Barcode";
import Home from "./Components/Home";
import { msalConfig, loginRequest } from "./auth/authConfig";
import { b2cPolicies } from './auth/policies';

const myMSALObj = new Msal.UserAgentApplication({
  ...msalConfig,
  auth: {
    ...msalConfig.auth,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
});

function App() {
  const [embedInfo] = useState(null);
  const [embedAddress] = useState(null);
  const [embedReportID] = useState(null);
  const [authAccount, setAuthAccount] = useState(() => {
    return sessionStorage.getItem("authAccount")
      ? JSON.parse(sessionStorage.getItem("authAccount"))
      : null;
  });

  useEffect(() => {
    myMSALObj.handleRedirectCallback((error, response) => {
      if (error) {
        console.error("MSAL Redirect Error:", error);
        if (error.errorMessage.includes("AADB2C90118")) {
          try {
            myMSALObj.loginRedirect({
              authority: b2cPolicies.authorities.forgotPassword.authority,
              ...loginRequest,
            });
          } catch (err) {
            console.error("Login Redirect Error:", err);
          }
        }
      } else if (response) {
        console.log("Authentication successful", response);
        const account = myMSALObj.getAccount();
        setAuthAccount(account);
        sessionStorage.setItem("authAccount", JSON.stringify(account));

        const url = new URL(window.location);
        url.hash = '';
        window.history.replaceState({}, document.title, url.toString());
      }
    });

    const accounts = myMSALObj.getAllAccounts();
    if (accounts.length > 0) {
      setAuthAccount(accounts[0]);
      sessionStorage.setItem("authAccount", JSON.stringify(accounts[0]));
    } else if (!authAccount) {
      try {
        myMSALObj.loginRedirect({
          ...loginRequest,
          authority: b2cPolicies.authorities.signUpSignIn.authority,
          navigateToLoginRequestUrl: false,
          responseMode: 'fragment',
        });
      } catch (err) {
        console.error("Login Redirect Error:", err);
      }
    }

    const url = new URL(window.location);
    if (url.search || url.hash) {
      url.search = '';
      url.hash = '';
      window.history.replaceState({}, document.title, url.toString());
    }
  }, [authAccount]);

  function logout() {
    console.log("Logging out...");

    if (window.powerbi && window.powerbi.powerBiEmbed && typeof window.powerbi.reset === "function") {
      console.log("Resetting Power BI embedded reports...");
      try {
        window.powerbi.reset();
      } catch (error) {
        console.error("Error resetting Power BI:", error);
      }
    } else {
      console.warn("Power BI is not available or already unmounted. Skipping reset.");
    }

    localStorage.removeItem('canSeeReports');
    console.log("Clearing MSAL cache...");
    myMSALObj.clearCache();

    sessionStorage.removeItem("authAccount");

    try {
      console.log("Calling MSAL logout...");
      myMSALObj.logout({
        postLogoutRedirectUri: "https://seiu-twr-mobile.azurewebsites.net/",
      });
    } catch (err) {
      console.error("Logout Error:", err);
    }

    setTimeout(() => {
      console.log("Redirecting to home after logout...");
      window.location.href = "https://seiu-twr-mobile.azurewebsites.net/";
    }, 1000);
  }

  if (!authAccount) {
    return <div>Loading authentication...</div>;
  }

  const account = authAccount;
  let memberID = account?.idTokenClaims?.jobTitle || "Unknown Member";

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <NavTop memberID={memberID} logout={logout} />
      {/* <NavTopSubMenu logout={logout} hasAccess={hasAccess} /> */}
      <Routes>
        <Route path="/" element={<Home memberID={memberID} />} />
        <Route path="/rsvpeventspage" element={<RSVPEventsPage memberID={memberID}/>} />
        <Route path="/dashboardgate" element={<DashBoardGate memberID={memberID} />} />
        <Route path="/reportembed" element={<ReportEmbed memberID={memberID} embedInfo={embedInfo} embedAddress={embedAddress} embedReportID={embedReportID} />} />
        <Route path="/rsvpupcomingevents" element={<RSVPUpcomingEvents memberID={memberID} />} />
        <Route path="/rsvpregisteredevents" element={<RSVPRegisteredEvents memberID={memberID} />} />
        <Route path="/contractdocs" element={<ContractDocs memberID={memberID} />} />
        <Route path="/barcode" element={<Barcode />} />
      </Routes>
    </div>
  );
}

export default App;