export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1A_SEIU_SIGNUP_SIGNIN",  // Ensure correct case
        forgotPassword: "B2C_1A_SEIU_PASSWORDRESET_SIGNIN" // ✅ Fixed Name
    },
    authorities: {
        signUpSignIn: {
            authority: "https://seiu1021B2C.b2clogin.com/seiu1021B2C.onmicrosoft.com/B2C_1A_SEIU_SIGNUP_SIGNIN",
        },
        forgotPassword: {
            authority: "https://seiu1021B2C.b2clogin.com/seiu1021B2C.onmicrosoft.com/B2C_1A_SEIU_PASSWORDRESET_SIGNIN", // ✅ Fixed Name
        },
    },
    authorityDomain: "seiu1021B2C.b2clogin.com",
};