import React, { Component } from 'react';
import NavBottom from './NavBottom';
import { RSVPUpcomingEvents } from './RSVPUpcomingEvents';
import { RSVPRegisteredEvents } from './RSVPRegisteredEvents';

class RSVPEventsPage extends Component {
  componentDidMount() {
    console.log("RSVPEventsPage memberID:", this.props.memberID);
  }

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <div style={{ flex: "1 1 auto", overflow: "auto", display: "flex", flexDirection: "column" }}>
          <div style={{ flex: "0 1 auto", padding: "10px", overflow: "auto" }}>
            <RSVPRegisteredEvents memberID={this.props.memberID} />
          </div>
          <div style={{ height: "10px", flexShrink: 0 }}></div> {/* Small dynamic space */}
          <div style={{ flex: "0 1 auto", padding: "10px"}}>
            <RSVPUpcomingEvents memberID={this.props.memberID} />
          </div>
        </div>
        <NavBottom />
      </div>
    );
  }
}

export default RSVPEventsPage;