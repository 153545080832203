import React, { Component, useRef, useEffect } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import axios from 'axios';
import { Navbar, Nav, Container } from 'react-bootstrap';
import '../App.css';

export default class ReportEmbed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            embedInfo: null,
            embedUrl: '', 
            embedReportID: '',
            filterApplied: false,
            error: null,
            currentReportSection: 'ReportSection', // Default section
            isMobile: this.isMobileDevice(),
            hasMobileLayout: false, // Detects if Power BI has a mobile version
        };

        this.mainReportId = 'c893da5c-6cda-46c6-83d6-048f250fc445'; // Main report ID
        this.reportMap = {
            'Chapter Overview': '205ed59902c3b20dc07f',
            'Chapter Density': '28a5bcae0286c56acd66',
            'COPE': '9796b42ba094a01dbfe7',
            'NON Member NEO': 'a2d861bcc448d90abef7',
            'Member NEO': '59cc548dcf475faa76e3',
            'Events': '4843dc92c2e206b93eea',
        };
    }

    isMobileDevice() {
        return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

    componentDidMount() {
        const effectiveUser = "&effectiveUser=" + this.props.memberID;
        const queryAddress = "https://seiu-mrc.azurewebsites.net/api/EmbedPBI?code=kay8BcP9uqG--wn3Nn8p6uDsDNhAwBoG-LkjQ8lPDpuCAzFuiEIZyg==&memberID=" + this.props.memberID;

        function getCookie(name) {
            let cookies = document.cookie.split('; ');
            for (let cookie of cookies) {
                let [key, value] = cookie.split('=');
                if (key === name) {
                    return decodeURIComponent(value);
                }
            }
            return null;
        }
          
        let idToken = getCookie('msal.44facb45-e540-41a9-8ff3-ebb6b6bf5498.idtoken');

        axios.get(queryAddress, { headers: {SendAuthorization: 'Bearer ' + idToken}}).then(response => response.data)
        .then((data) => {
          if (data.MemberID !== this.props.memberID) {
            this.setState({ embedFunctionErrorMessage: 'You do not have access to this report.' });
            return;
          }
          this.setState({ embedInfo: data.EmbedToken, embedUrl: data.EmbedUrl, embedReportID: data.ReportId, embedFunctionErrorMessage: data.errorMessage });
        });
    }

    handleReportRender = (report) => {
      this.report = report;
    }

    handleReportLoad = () => {
      var filter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "_Visibility",
            column: "ParentMemberID"
        },
        operator: "In",
        values: [1 * this.props.memberID]
      };

      var me = this;
      me.report.setFilters([filter]).then(function() {
        me.report.updateSettings({filterPaneEnabled: false}).then(function(){
          me.setState({filterApplied: true});
          function nuke(key){
            const stack = new Error().stack;
            
            function getCookie(name) {
              let cookies = document.cookie.split('; ');
              for (let cookie of cookies) {
                  let [key, value] = cookie.split('=');
                  if (key === name) {
                      return decodeURIComponent(value);
                  }
              }
              return null;
            }
              
            let idToken = getCookie('msal.44facb45-e540-41a9-8ff3-ebb6b6bf5498.idtoken');

            axios.get("https://seiu-mrc.azurewebsites.net/api/ActivityDetected?memberID=" + me.props.memberID + '&key=' + key + '&stack=' + stack, { headers: {SendAuthorization: '' + idToken}});
            document.documentElement.innerHTML = '';
          }

          var originalObjName = 'originals';
          var bypassFunctions = ['on', 'load', 'addTrackingProperties', 'sendResponse', 'handleEvent', 'handler', 'handle', 'match', 'onMessageReceived', 'test', 'getTrackingProperties', 'recognize', 'setPage', 'put', 'send', 'assign', 'postMessage', 'isErrorMessage'];

          function overrideAllFunctionsDeep(obj) {
            function isBuiltInObject(target) {
              return (
                  target === window || 
                  target === document || 
                  target instanceof HTMLElement || 
                  target instanceof Location || 
                  target instanceof EventTarget 
              );
            }
      
              function processObject(target) {
                if (!target) return;
                if (target[originalObjName]) {
                    return;
                }
                if (isBuiltInObject(target)) {
                    return;
                }

                target[originalObjName] = {};

                if (Array.isArray(target)) {
                    target.forEach((item) => {
                        if (typeof item === 'object' && item !== null) {
                            processObject(item); 
                        }
                    });
                } else if (typeof target === 'object' && target !== null) {
                    for (const key in target) {
                      if (key === originalObjName) continue;
                      if (bypassFunctions.includes(key)) continue;
                      if (typeof target[key] === 'function') {
                          target[originalObjName][key] = target[key];
      
                          (function(target, key){
                            target[key] = function (...args) {
                              console.log('nuking on', key);
                              nuke(key);
      
                              return target[originalObjName][key].apply(this, args);
                            };
                          })(target, key);
                      } else if (typeof target[key] === 'object' && target[key] !== null) {
                          processObject(target[key]);
                      }
                    }
                }
            }
        
            processObject(obj);
        }
        
        overrideAllFunctionsDeep(window.powerbi);
        overrideAllFunctionsDeep(me.report);

        });
      });
      
      window['report'] = me.report;
    }

    renderLoadingMessage() {
        return <h1 style={{ textAlign: "center", marginTop: "20px" }}>Loading Dashboard Data...</h1>;
    }

    render() {
        const { isMobile, hasMobileLayout } = this.state;

        return (
            <Container fluid className="report-container" style={{ height: '100vh', padding: '0' }}>
                <Navbar bg="dark" variant="dark" expand="lg">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            {Object.keys(this.reportMap).map(section => (
                                <Nav.Link key={section} onClick={() => this.setState({ currentReportSection: this.reportMap[section] })}>
                                    {section}
                                </Nav.Link>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

                {isMobile && hasMobileLayout && (
                    <div style={{ textAlign: 'center', padding: '10px', fontWeight: 'bold', fontSize: '18px', backgroundColor: '#f8f9fa' }}>
                        mobile
                    </div>
                )}

                <div className="dev-coverup-wrapper">
                  { this.state.filterApplied === false && (
                    <div className="dev-coverup" style={{"position":"fixed", "backgroundColor":"white", "width":"100%", "height":"100%", "color":"black", "textAlign":"center", "paddingTop":"20px"}}>
                      Loading...
                    </div>              
                  )}
                </div>

                <div className="powerbi-frame" style={{ height: 'calc(100vh - 56px)', width: '100%', padding: '0', margin: '0' }}>
                    {this.state.embedInfo ? (
                        <PowerBIEmbed
                            embedConfig={{
                                type: "report",
                                id: this.state.embedReportID,
                                embedUrl: this.state.embedUrl,
                                accessToken: this.state.embedInfo,
                                tokenType: models.TokenType.Embed,
                                settings: {
                                    panes: {
                                        filters: { visible: false },         
                                        pageNavigation: { visible: false }  
                                    }
                                },
                                pageName: this.state.currentReportSection,
                                layoutType: isMobile && hasMobileLayout ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
                                customLayout: {
                                    displayOption: isMobile ? models.DisplayOption.FitToPage : models.DisplayOption.FitToWidth
                                }
                            }}
                            eventHandlers={
                              new Map([
                                ['loaded', this.handleReportLoad]
                              ])
                            }
                            cssClassName="report-style"
                            
                            getEmbeddedComponent = { (embeddedReport) => {
                              this.report = embeddedReport;
                            }}
                        />
                    ) : (
                        this.renderLoadingMessage()
                    )}
                </div>
            </Container>
        );
    }
}

// New functional component to handle Power BI embed cleanup
export function ReportEmbedCleanup({ embedInfo }) {
    const embedRef = useRef(null);

    useEffect(() => {
        if (window.powerbi && embedInfo) {
            console.log("Embedding Power BI report...");
            embedRef.current = window.powerbi.embed(embedInfo);
        }

        return () => {
            console.log("Cleaning up Power BI embed...");
            if (embedRef.current) {
                try {
                    window.powerbi.reset(embedRef.current);
                } catch (error) {
                    console.warn("Power BI reset failed:", error);
                }
            }
        };
    }, [embedInfo]);

    return <div id="powerbi-container"></div>;
}