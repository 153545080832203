import React, { Component } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import axios from 'axios';
// import NavBottom from './NavBottom';
import { RSVPUpcomingEventsMain } from './RSVPUpcomingEventsMain';
import { Spinner } from 'react-bootstrap';
import './Home.css'; // Import the CSS file

const extraSettings = {
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
};

export class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            embedInfo: "",
            embedAddress: "",
            embedReportID: "",
            viewportHeight: window.innerHeight, // Capture initial viewport height
        };
    }

    componentDidMount() {
        const effectiveUser = `&effectiveUser=${this.props.memberID}`;
        const queryAddress = `https://seiu-twr-powerbiembedfunc.azurewebsites.net/api/MRCPRDHomeKPIEmbed?code=AzxCyhFfaWLcSddT3H5zsxeGag8aR3e9R/ctdnWEwe678WRJbGDXVw==${effectiveUser}`;

        axios.get(queryAddress)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    embedInfo: data.EmbedToken,
                    embedAddress: data.EmbedUrl,
                    embedReportID: data.ReportId
                });
            });

        // Update viewport height on resize
        window.addEventListener('resize', this.updateViewportHeight);
    }

    componentWillUnmount() {
        // Clean up resize listener
        window.removeEventListener('resize', this.updateViewportHeight);
    }

    updateViewportHeight = () => {
        this.setState({ viewportHeight: window.innerHeight });
    };

    render() {
        const { embedInfo, embedAddress, embedReportID } = this.state;

        return (
            <div className="grid-container">
                {/* Alert Message */}
                <div className="mrc-message">
                    <span>The MRC portal data is secure and only made available to authorized users. This is not a public website and information needs to be treated as confidential.</span>
                </div>

                {/* RSVP Events */}
                <div className="rsvp-events">
                    <RSVPUpcomingEventsMain memberID={this.props.memberID} />
                </div>

                {/* PowerBI Report */}
                <div className="powerbi-report">
                    {embedInfo ? (
                        <PowerBIEmbed
                            embedConfig={{
                                type: "report",
                                id: embedReportID,
                                embedUrl: embedAddress,
                                accessToken: embedInfo,
                                tokenType: models.TokenType.Embed,
                                settings: extraSettings,
                                permissions: models.Permissions.View,
                            }}
                            cssClassName="report-style"
                        />
                    ) : (
                        <div className="loading-container">
                            <Spinner animation="border" style={{ color: "purple", marginRight: "2px" }} />
                            <h1>Loading Dashboard Data...</h1>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Home;